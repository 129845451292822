import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Moment from 'moment'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'animate.css';
import '@/utils/dislog.js'

Vue.config.productionTip = false

Vue.use(ElementUI);
import commTool from './utils/commTool'
import apis from './utils/api'
import pinyin from "pinyin-match";
Vue.prototype.$pinyin=pinyin

Vue.prototype.$moment=Moment
window.ctool = commTool;
window.capis = apis;

Vue.directive('has', {
  // 当被绑定的元素插入到 DOM 中时触发bind钩子
  inserted: function (el, binding) {
    if (!permissionJudge(binding.value)) {
      el.parentNode.removeChild(el);
    }
    function permissionJudge(value) {
      let list = store.state.routers
      for (let item of list) {
        if (item == value) {
          return true;
        }
      }
      return false;
    }

  }
})


router.beforeEach((to, from, next) => {
  
  const token = store.state.token ||false;  // 获取token
  if(to.path == '/login'){
   return next()
  }
  let list = ['/login']
  let str=store.state.routers
  if(!token || !str) return  next('/login')

  list= str
//   console.log(`@@@`)
//   console.log(to)
// console.log(list)
    if(list.includes(to.path)){
       next()
    }else{
      commTool.msgWarn('没有权限访问该页面')
      next(from.path)
    }
  
})

new Vue({
  router,
  store,
  render: h => h(App),
  created(){
       // 实例创建完成后被立即调用rem换算方法,解决内容闪现问题
		
       if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
        setRem();
        window.addEventListener('resize', setRem);//浏览器窗口大小改变时调用rem换算方法
       }
      //  else{
      //     setRemPC()  

      //     window.addEventListener('resize', setRemPC);//浏览器窗口大小改变时调用rem换算方法
      //  }
  }
}).$mount('#app');

//  // 手机端
//  function setRem() {
//   var whdef = 100 / 750; // 表示750的设计图,使用100PX的默认值
//   var bodyWidth = document.body.clientWidth; // 当前窗口的宽度
//   var rem = bodyWidth * whdef; // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
//   document.getElementsByTagName('html')[0].style.fontSize = rem + 'px';
// }
// //pc端
// function setRemPC() {
//   var whdef = 100 / 1920; // 表示1920的设计图,使用100PX的默认值
//   var bodyWidth = document.body.clientWidth; // 当前窗口的宽度
//   var rem = bodyWidth * whdef; // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
//   document.getElementsByTagName('html')[0].style.fontSize = rem + 'px';
// }
