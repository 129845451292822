<template>
  <div id="app" v-cloak>
    <!-- <Header v-if='isshow'/> -->
    <div class="body">
        <router-view/>
    </div>
    <!-- <Foot  v-if='isshow'/> -->
  </div>
</template>
<script>

export default {
   data(){
     return {
      isshow:true
     }
   },
   watch:{
    //  $route(e){
    //   //  console.log(e)
    //    if(e.name=='Login'){
    //      this.isshow=false
    //    }else{
    //     this.isshow=true
    //    }
      
    //  }
   },    components:{

    }
}
</script>

<style >
input[aria-hidden=true]{display: none !important}
[v-cloak] {
  display: none;
}
html, body, div, span, applet, object, iframe,

h1, h2, h3, h4, h5, h6, p, blockquote, pre,

a, abbr, acronym, address, big, cite, code,

del, dfn, em, img, ins, kbd, q, s, samp,

small, strike, strong, sub, sup, tt, var,

b, u, i, center,

dl, dt, dd, ol, ul, li,

fieldset, form, label, legend,

table, caption, tbody, tfoot, thead, tr, th, td,

article, aside, canvas, details, embed,

figure, figcaption, footer, header, hgroup,

menu, nav, output, ruby, section, summary,

time, mark, audio, video {
margin: 0;

padding: 0;

border: 0;

font-size: 100%;

font: inherit;

vertical-align: baseline;

}

/* HTML5 display-role reset for older browsers */

article, aside, details, figcaption, figure,

footer, header, hgroup, menu, nav, section {
display: block;

}

body {
line-height: 1;

}

ol, ul {
list-style: none;

}

blockquote, q {
quotes: none;

}

blockquote:before, blockquote:after,

q:before, q:after {
content: '';

content: none;

}

table {
border-collapse: collapse;

border-spacing: 0;

}

a {
text-decoration: none;

color: inherit;

}

</style>
<style lang="scss">
.body{
  // min-height:calc(100vh - 340px);
  // background: rgb(248, 248, 248);
  // padding-bottom: 20px ;
  // position: relative;
  min-width: 1200px;
}
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }

// nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>
